import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Pulse from 'react-reveal/Pulse';

import SvgIcon from "../../../common/SvgIcon";
import Button from "../../../common/Button";

import * as S from "./styles";

const RightHeroBlock = ({ title, subtitle, content, button, icon, t, id }) => {

  return (
    <S.RightBlockContainer>
      <Row type="flex" justify="space-between" align="middle" id={id}>
        <Col lg={10} md={11} sm={11} xs={24}>
          <Pulse>
            <S.ContentWrapper>
              <b><p style={{color: `#00FF33`}}>{t(subtitle)}</p></b>
                <S.TitleWrapper>
                  <h1>{t(title).toUpperCase()}</h1>
                </S.TitleWrapper>
              <S.Content><h2>{t(content)}</h2></S.Content>
              <S.ButtonWrapper>
                {button &&
                  typeof button === "object" &&
                  button.map((item, id) => {
                    return (
                      <Button
                        key={id}
                        color={item.color}
                        width="true"
                        onClick={() => window.open('https://calendly.com/cpm-/cita-manager?month=2021-10')}
                      >
                        {t(item.title)}
                      </Button>
                    );
                  })}
              </S.ButtonWrapper>
            </S.ContentWrapper>
          </Pulse>
        </Col>
        <Col lg={12} md={11} sm={12} xs={24}>
          <Pulse>
            <SvgIcon
              src={icon}
              className="about-block-image"
              width="100%"
              height="100%"
            />
          </Pulse>
        </Col>
      </Row>
    </S.RightBlockContainer>
  );
};

export default withTranslation()(RightHeroBlock);
