import styled from 'styled-components';

export const RightBlockContainer = styled.section`
  position: relative;
  padding: 0 12rem 2rem 10rem;
  background: #000000;

  @media only screen and (max-width: 1600px) {
    padding: 2rem 8rem 2rem 10rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 2rem 4rem 4rem 2rem;
  }
  @media only screen and (max-width: 414px) {
    padding: 1rem 2rem 2rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.p`
  padding: 0 9rem 0 0;
  @media only screen and (max-width: 414px) {
    padding: 0 2rem 0 0;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  max-width: 300px;

  @media only screen and (max-width: 480px) {
    text-align: center;
    padding-bottom: 1.5rem;
  }
`;

export const TitleWrapper = styled.div`
  color: #EBEBEB;
  font-size: 0.37rem;
  padding: 0 1.56rem 0 0;
  font-weight: 600;
`;